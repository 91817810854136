/**
 * Global/Base JavaScript
 * ======================
 * This JavaScript file is imported by BOTH `entry.es6.js` and `entry.es5.js`
 */

// ** Utilities
// * ----------
// *
import { ready } from "./utils/dom";
import cookies from "./utils/cookies";
import { prefersReducedMotion } from "./utils/window";

// ** Project-wide features
// * ----------------------
// *
import safeLinks from "./base/safeLinks";

// ** Component imports
// * ------------------
// *
import "../../components/structure/cookies/scripts/cookies";
import "../../components/structure/navigation/scripts/navigation";
import "../../components/structure/onetrustconsentbanner/scripts/onetrustconsentbanner";
import "../../components/content/table/scripts/table"; // Table import before accordion/tabs
import "../../components/content/tabs/scripts/tabs";
import "../../components/content/toc/scripts/toc";
import "../../components/content/toc/scripts/toclist";

ready(() => {
    // console.info("App JavaScript loaded & ready");

    safeLinks();

    // Expose useful utilities on the project namespace, so Devs & QA can
    // quickly assess/effect environment
    window.SII.cookies = cookies;
    window.SII.prefersReducedMotion = prefersReducedMotion();
});
