import { isAuthor, getPageTemplate } from "../../../../global/js/utils/aem";
import cookies from "../../../../global/js/utils/cookies";
import { isElement } from "../../../../global/js/utils/element";

const cookieName = `${SII.brand}_COOKIES_ACCEPTED`; // 1: true; 0: false?
const banner = document.querySelector("[data-cookie-banner]");

function removeBanner(parentNode) {
    return parentNode.removeChild(banner);
}

function getElements() {
    const container = banner.parentNode;

    return {
        container,
        banner: removeBanner(container) // Detach & store the banner element
    };
}

function addButtonEvents(elements) {
    banner.addEventListener(
        "click",
        function choiceEvent(e) {
            const button = e.target.closest("button");

            if (button) {
                if (button.getAttribute("data-cookies").toUpperCase() === "ACCEPT") {
                    cookies.set(cookieName, 1, { "max-age": 2592000 }); // Expires at 30 days
                } else {
                    cookies.set(cookieName, 0); // Session cookie only
                }

                removeBanner(elements.container);
            }
        },
        false
    );
}

export default (function createCookieNotification() {
    // Exit early conditions...
    if (!isElement(banner) || (isAuthor() && getPageTemplate() === "HOME")) {
        return;
    }

    const elements = getElements();

    if (!cookies.get(cookieName)) {
        // Complete banner set-up ...
        banner.hidden = false;
        elements.container.append(elements.banner);
        addButtonEvents(elements);
    }
})();
