import { isElement } from "../../../../global/js/utils/element";
import { matchMediaOnChange } from "../../../../global/js/utils/window";

const menuBtn = document.querySelector("[aria-controls='navigation']");
const navigation = document.querySelector("#navigation");

const css = {
    navigation: {
        closed: "navigation--closed",
        breakpoint: "--mql_navigation_switch"
    }
};

function isOpen(element) {
    return !!(element.getAttribute("aria-expanded") === "true");
}

function broadcastSwitchEvent(data) {
    window.SII.viewport = data;
    window.dispatchEvent(
        new CustomEvent("navigation:switched", {
            detail: {
                ...data
            }
        })
    );
}

function resetMobileView() {
    navigation.hidden = true;
    menuBtn.setAttribute("aria-expanded", false);
    navigation.classList.add(css.navigation.closed);
    broadcastSwitchEvent({ desktop: false });
}

function resetDesktopView() {
    menuBtn.setAttribute("aria-expanded", true);
    navigation.classList.remove(css.navigation.closed);
    navigation.hidden = false;
    broadcastSwitchEvent({ desktop: true });
}

function toggleNavigation() {
    const expand = !isOpen(menuBtn);

    if (expand) {
        navigation.hidden = false;
        // Include slight pause to ensure opening animation is visible
        // This gets round older Safari's lack of support for native JS
        // `transitionstart` event
        setTimeout(() => {
            navigation.classList.remove(css.navigation.closed);
        }, 50);
    } else {
        // Don't hide navigation here, it's done by the `transitionend` event,
        // once the animation has completed
        navigation.classList.add(css.navigation.closed);
    }

    menuBtn.setAttribute("aria-expanded", expand);
}

function navigationSwitch() {
    const styles = window.getComputedStyle(document.documentElement);
    const breakpoint = styles.getPropertyValue(css.navigation.breakpoint);
    const mql = window.matchMedia(`(min-width: ${breakpoint})`);

    if (breakpoint.length === 0) {
        return;
    }

    if (!mql.matches) {
        resetMobileView();
    } else {
        resetDesktopView();
    }

    mql.addEventListener(
        "change",
        (e) => {
            if (e.matches) {
                resetDesktopView();
            } else {
                // Ensures links in mobile navigation drawer not accessible when closed
                resetMobileView();
            }
        },
        false
    );
}

export default (function initNavigation() {
    if (
        // Browser doesn't support `MediaQueryList.onchange` event
        !matchMediaOnChange() ||
        // Page missing necessary navigation elements...
        !isElement(menuBtn) ||
        !isElement(navigation)
    ) {
        // ...exit early
        return;
    }

    navigationSwitch();
    // If JavaScript doesn't kick-in, navigation is visible; safest fallback

    menuBtn.addEventListener("click", toggleNavigation, false);

    navigation.addEventListener(
        "transitionend",
        (e) => {
            const { propertyName } = e;
            if (!isOpen(menuBtn) && propertyName === "max-height") {
                navigation.hidden = true;
            }
        },
        false
    );
})();
