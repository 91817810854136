/**
 * AEM utilities
 * =============
 * Index
 *
 * - getEditorMode()
 * - getPageTemplate()
 * - isAuthor()
 */

import { classListArray } from "./dom";

/**
 * Return the specific AEM editor view mode
 *
 * Possible returned values:
 * - DEVELOPER (Content view only)
 * - INITIAL (Template view only)
 * - LAYOUTING (Content & Templates views)
 * - PREVIEW (Content & Templates views)
 * - STRUCTURE (Template view only)
 * - TIMEWARP (Content view only)
 */

export function getEditorMode() {
    const html = document.querySelector("html"); // No check, as page will always have HTML-tag
    const css = classListArray(html).filter((c) => c.includes("aem-AuthorLayer") && !c.includes("hidePlaceholder"));

    if (css.length == 0) {
        return;
    }

    return css[0].replace(/^aem-AuthorLayer-/i, "").toUpperCase();
}

export function getPageTemplate() {
    const body = document.querySelector("body");
    const css = classListArray(body).filter((c) => c.includes("tmpl-"));

    if (css.length == 0) {
        return;
    }

    return css[0].replace(/^tmpl-/i, "").toUpperCase();
}

/**
 * Detect when AEM page is in author mode/environment;
 * as opposed to publish environment or query string `wcmmode=disabled`
 * @note: Repeated in SII project namespace for immediate use in page
 */
export function isAuthor() {
    return !!document.querySelector("html[data-mode='author']");
}

export default {
    getEditorMode,
    getPageTemplate,
    isAuthor
};
