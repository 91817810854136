/**
 * Element utilities
 * =================
 * Index
 *
 * - booleanAttributes -- Array (not exported)
 * - createElement(tagName, attributes)
 * - detectOverflow(element)
 * - getAttributes(element)
 * - getPosition(element)
 * - isElement(element)
 */

/**
 * According to the HTML specification
 * (https://html.spec.whatwg.org/#boolean-attribute)
 *
 *  "The presence of a boolean attribute on an element represents the “true” value,
 *   and the absence of the attribute represents the “false” value."
 */
const booleanAttributes = [
    "allowfullscreen",
    "allowpaymentrequest",
    "async",
    "autofocus",
    "autoplay",
    "checked",
    "controls",
    "default",
    "disabled",
    "formnovalidate",
    "hidden",
    "ismap",
    "itemscope",
    "loop",
    "multiple",
    "muted",
    "nomodule",
    "novalidate",
    "open",
    "playsinline",
    "readonly",
    "required",
    "reversed",
    "selected",
    "truespeed"
];

/**
 * Create an element with optional attributes object
 *
 * @param {String} tagName
 * @param {Object} attributes
 */
export function createElement(tagName, attributes = {}) {
    const element = document.createElement(tagName);

    Object.keys(attributes).forEach((attribute) => {
        if (booleanAttributes.includes(attribute)) {
            element[attribute] = attributes[attribute];
        } else {
            element.setAttribute(attribute, attributes[attribute]);
        }
    });

    return element;
}

/**
 * Detect if an element overflows its container
 * @param {Element} element
 * @returns
 */
export function detectOverflow(element) {
    return {
        horizontal: element.scrollWidth > element.clientWidth,
        vertical: element.scrollHeight > element.clientHeight
    };
}

/**
 * Return an element's attributes as an object of key/value pairs
 *
 * @param {Element} element
 */
export function getAttributes(element) {
    const attributes = {};
    if (isElement(element)) {
        for (let i = 0; i < element.attributes.length; i += 1) {
            const { nodeName, nodeValue } = element.attributes[i];

            if (booleanAttributes.includes(nodeName)) {
                attributes[nodeName] = true;
            } else {
                attributes[nodeName] = nodeValue;
            }
        }
    }

    return attributes;
}

/**
 * Get true element position (relative to page)
 * Unaffected by parent elements being alternatively positioned via CSS
 * e.g. items that are `position: fixed/sticky`
 *
 * @param {Element} element
 *
 * @returns {Object}
 */
export function getPosition(element) {
    const position = {
        top: 0,
        left: 0
    };

    if (element.offsetParent) {
        while (element) {
            position.top += element.offsetTop;
            position.left += element.offsetLeft;
            element = element.offsetParent;
        }
    }

    return position;
}

/**
 * Test for true DOM element/node status
 * E.g. Good to check before calling further node-specific methods, which will
 * throw JS errors on non-element items
 *
 * @param {Element} element
 */
export function isElement(element) {
    return element instanceof Element;
}

export default {
    createElement,
    detectOverflow,
    getAttributes,
    getPosition,
    isElement
};
