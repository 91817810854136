/**
 * A very stripped-down version of expand/collapse panels
 * - No animation (e.g. not suitable for navigation)
 * - Treats all expandable sections individually, unlike an accordion
 *   (although functionality here could provide some boilerplate functions
 *   for an accordion)
 */

import { nodeListArray } from "./dom";

const expandSelector = `[data-expandable]`;

function getPanel(button) {
    return document.querySelector(`#${button.getAttribute("aria-controls")}`);
}

function toggle(e) {
    const { target } = e;
    const button = target.closest("[aria-controls]");
    const expanded = button.getAttribute("aria-expanded") === "true";
    return !expanded ? open(button) : close(button);
}

function init(buttons) {
    buttons.forEach((button) => {
        const id = button.getAttribute("aria-controls");

        if (!id) {
            console.warn("Expandable item is missing 'aria-controls' attribute: ", button);
            return;
        }

        button.addEventListener("click", toggle, false);
        open(button);
    });
}

export function destroy(buttons) {
    buttons.forEach((button) => {
        button.removeEventListener("click", toggle);
        open(button);
    });
}

export function open(button) {
    const panel = getPanel(button);
    button.setAttribute("aria-expanded", true);
    panel.hidden = false;
}

export function close(button) {
    const panel = getPanel(button);
    button.setAttribute("aria-expanded", false);
    panel.hidden = true;
}

export default function expandable(targetElement) {
    const items = nodeListArray(targetElement.querySelectorAll(expandSelector));
    init(items);
}
