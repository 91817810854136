import debounce from "../../../../global/js/utils/debounce";
import { nodeListArray } from "../../../../global/js/utils/dom";
import { detectOverflow } from "../../../../global/js/utils/element";

const tableParent = ".table-overflow";
const scrollNotification = ".scroll-notification";

function getTableComponents(container) {
    const element = container || document;
    return nodeListArray(element.querySelectorAll(tableParent));
}

function setTabIndex(tablesSubset) {
    const tables = tablesSubset || getTableComponents();

    tables.forEach((table) => {
        const notification = table.querySelector(scrollNotification);

        if (!detectOverflow(table).horizontal) {
            table.removeAttribute("tabIndex");
            notification.hidden = true;
            return;
        }

        table.setAttribute("tabIndex", "0");
        notification.hidden = false;
    });
}

export default (function createScrollingTables() {
    setTabIndex();

    // Recalculate table overflow on browser resize event
    window.addEventListener(
        "resize",
        debounce(() => setTabIndex())
    );

    // @edgecase:
    // May need to recalculate table overflow on any tables inside
    // an opening accordion/tabs component
})();
