/**
 * Core Table-of-Contents (TOC) functionality
 * ==========================================
 * Create Heading Ids for headings in a page and anchor links to them.
 * Shared by any variant of TOC: list/buttons
 *
 */

import { nodeListArray } from "../../../../global/js/utils/dom";
import { camelCase, truncate } from "../../../../global/js/utils/string";

import scroll from "./scroll";

const idMemo = [];

const targetSelector = `nav[data-toc]`;
const listSelector = "ol[data-toc-list]";
const contentSelector = "main";
const headingSelector = "h1, h2, h3, h4, h5, h6";

const targets = nodeListArray(document.querySelectorAll(targetSelector));

function fixDuplicateIds(idValue) {
    const duplicateCount = idMemo.filter((memorizedId) => memorizedId === idValue).length;
    idMemo.push(idValue); // Store the original id, so we can track how many duplicates exist
    return duplicateCount === 0 ? idValue : `${idValue}-${duplicateCount}`;
}

function setIds(heading) {
    heading.setAttribute("tabindex", "-1"); // Fix IE11 tab-stop bug
    heading.classList.add("heading--toc");

    // If an element already has an id, use it
    if (heading.hasAttribute("id")) {
        return heading;
    }
    // ...Or, create one
    const id = truncate(camelCase(heading.textContent), 30);
    heading.setAttribute("id", fixDuplicateIds(id));

    return heading;
}

function filterHeadings() {
    const ignoreAttribute = "data-toc-ignore";
    const main = document.querySelector(contentSelector);
    const mainHeadings = nodeListArray(main.querySelectorAll(headingSelector));

    return mainHeadings
        .filter((heading) => {
            if (heading.hasAttribute(ignoreAttribute) || heading.closest(`[${ignoreAttribute}]`)) {
                return false;
            }
            return heading;
        })
        .map((heading) => setIds(heading));
}

function setListItems() {
    return filterHeadings().map((heading) => {
        const tag = heading.tagName;
        const css = tag === "H1" || tag === "H2" ? ` class="section-heading"` : "";
        return `<li><a href="#${heading.getAttribute("id")}"${css}>${heading.textContent.trim()}</a></li>`;
    });
}

export default (function createTableOfContents() {
    // Exit early
    if (!targets.length) {
        return;
    }

    const output = setListItems();

    targets.forEach((target) => {
        const headingsList = target.querySelector(listSelector);
        headingsList.innerHTML = output.join("");
        target.hidden = false;
        scroll(target);
    });
})();
