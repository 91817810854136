/**
 * String utilities
 * ================
 * Index
 *
 * - camelCase(strInput)
 * - truncate(strInput)
 */

/**
 * Transform a string to camel-case output
 *
 * @param   {String}  strInput
 *
 * @return  {String}
 */
export function camelCase(strInput) {
    if (!strInput) {
        return "";
    }

    return String(strInput)
        .trim() // Ensure no space characters at start/end
        .toLowerCase() // Transform string to all lowercase characters
        .replace(/[\s]+/g, "$") // Replace space characters with a placeholder character
        .replace(/(\$)(\w)/g, function upperCaseFirstCharacter(match, placeholder, firstChar) {
            return firstChar.toUpperCase();
        })
        .replace(/[^\w]+/g, ""); // One final pass to remove special characters
}

/**
 * Truncate a string to a character limit
 *
 * @param   {String}  strInput
 * @param   {Number|Undefined}  characterMaximum
 *
 * @return  {String}
 */
export function truncate(strInput, characterMaximum) {
    if (!strInput) {
        return "";
    }

    return String(strInput).trim().split("").slice(0, characterMaximum).join("");
}

export default {
    camelCase,
    truncate
};
