/**
 * TOC (List) variant-specific functionality
 * =========================================
 * On mobile viewports, make the TOC expand/collapsable
 *
 * @edgecase solution:
 * -- Detect if TOC is taller than available window height
 * -- Apply sticky styling only if is NOT
 *
 *    Ensures visitor can always access all TOC nav links without having to
 *    scroll all the way to the bottom of the page (which would be entirely
 *    counter-productive)
 *
 */

import debounce from "../../../../global/js/utils/debounce";
import { nodeListArray } from "../../../../global/js/utils/dom";
import expandable, { open, close } from "../../../../global/js/utils/expandable";

import addMarker from "./marker";

const targetSelector = `nav[data-toc="list"]`;
const targets = nodeListArray(document.querySelectorAll(targetSelector));

function tocFollowPageScroll(tocElement) {
    const tocTaller = tocElement.clientHeight > window.innerHeight;
    tocElement.classList[tocTaller ? "remove" : "add"]("sticky");
}

function setExpandStatus(target, viewportData = {}) {
    const { desktop } = viewportData;
    const button = target.querySelector("button");
    return desktop ? open(button) : close(button);
}

export default (function createTocListExtras() {
    // Exit early
    if (!targets.length) {
        return;
    }

    targets.forEach((target) => {
        tocFollowPageScroll(target);
        expandable(target);
        setExpandStatus(target, window.SII.viewport);
        addMarker(window.SII.viewport);
    });

    window.addEventListener(
        "resize",
        debounce(() => {
            targets.forEach((target) => {
                tocFollowPageScroll(target);
            });
        }),
        false
    );

    window.addEventListener("navigation:switched", (e) => {
        const { detail } = e;
        targets.forEach((target) => setExpandStatus(target, detail));
    });
})();
