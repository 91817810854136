/**
 * (TOC) Anchor scrolling functionality
 * ====================================
 * - If visitor's OS/browser is set to `prefers-reduced-motion`, enhancement is skipped
 * - Caps the maximum animated scroll behaviour, so a visitor clicking a 'jump'-link
 *   to the bottom of a very long page will not have to watch several seconds of
 *   page scroll animation - vomit inducing!
 *
 * Implementation
 * --------------
 * 1.) Import the scroll function - `import scroll from "./PATH_TO/scroll";`
 * 2.) Call `scroll(observerElement)` - passing-in the element that holds the 'jump'-links
 *
 * Key settings
 * ------------
 * - `scrollBuffer`:
 *    The maximum number of pixels the page will animate scrolling
 * - `animationDuration`:
 *    The maximum duration for the animation (milliseconds)
 */

import { getPosition, isElement } from "../../../../global/js/utils/element";
import { prefersReducedMotion } from "../../../../global/js/utils/window";

const scrollBuffer = 150;
const animationDuration = 250;

function getElementId(href) {
    if (!href || typeof href !== "string") {
        return;
    }
    // If `href` doesn't begin `#`, it can be assumed to be in another page
    // We are currently not supporting scroll to anchors in other pages
    return href.startsWith("#") && href;
}

function getTargetElement(href) {
    const id = getElementId(href);
    return id && document.querySelector(id);
}

/**
 * Using `requestAnimationFrame` to (more finely) control the animation behaviour
 */
function runAnimation(options = {}) {
    const { start, direction } = options;
    const animation = {
        id: null,
        start: null,
        duration: animationDuration
    };

    const easeInQuad = function (t) {
        return t * t;
    };

    function step(timestamp) {
        if (!animation.start) {
            animation.start = timestamp;
        }

        const elapsedTime = timestamp - animation.start;
        const percent = Math.min(elapsedTime / animation.duration, 1);

        window.scrollTo({
            top: start + scrollBuffer * direction * easeInQuad(percent),
            behavior: "smooth"
        });

        if (percent === 1) {
            window.cancelAnimationFrame(animation.id);
            return;
        }

        window.requestAnimationFrame(step);
    }

    animation.id = window.requestAnimationFrame(step);
}

function jumpTo(positionY) {
    const offset = positionY - window.scrollY;
    const direction = offset > 0 ? 1 : -1;
    const firstPosition = positionY - scrollBuffer * direction;

    window.scrollTo({
        top: firstPosition
    });

    // Then run animation for the rest of the buffer distance...
    runAnimation({
        start: firstPosition,
        direction
    });
}

function isWithinBuffer(y) {
    const lowerLimit = y - scrollBuffer;
    const upperLimit = y + scrollBuffer;
    const currentScrollY = window.scrollY;
    return currentScrollY > lowerLimit && currentScrollY < upperLimit;
}

export default function scroll(observerElement) {
    if (prefersReducedMotion() || !isElement(observerElement)) {
        return;
    }

    observerElement.addEventListener(
        "click",
        (e) => {
            const { target } = e;

            if (target.tagName.toUpperCase() === "A") {
                const anchor = getTargetElement(target.getAttribute("href"));

                if (isElement(anchor)) {
                    const anchorPosition = getPosition(anchor).top;
                    e.preventDefault();

                    if (!isWithinBuffer(anchorPosition)) {
                        jumpTo(anchorPosition);
                    } else {
                        window.scrollTo({
                            top: anchorPosition
                        });
                    }
                }
            }
        },
        false
    );
}
