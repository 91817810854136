/**
 * TOC (List) marker functionality
 * ===============================
 * Adds a marker dot to the TOC (List) links as the headings are scrolled into view.
 *
 * - Behaviour is only observed on desktop viewports (where the Report template
 *   switches to two-column)
 *
 * - IE11 does not natively support the `IntersectionObserver` API;
 *   it will skip this code to avoid having to add unnecessary polyfill code
 *
 */

import { nodeListArray } from "../../../../global/js/utils/dom";

const intersectionOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 1
};

const tocHeadings = nodeListArray(document.querySelectorAll(".heading--toc"));
const tocLinks = nodeListArray(document.querySelectorAll("[data-toc-list] a"));

function setCurrent(observed) {
    observed.forEach((heading) => {
        if (!heading.isIntersecting) {
            return;
        }

        const { target } = heading;
        tocLinks.forEach((link) => link.classList.remove("highlight"));
        document.querySelector(`a[href="#${target.id}"]`).classList.add("highlight");
    });
}

function init(isDesktop) {
    const observeHeadings = new IntersectionObserver(setCurrent, intersectionOptions);

    tocHeadings.forEach((heading) => {
        observeHeadings[isDesktop ? "observe" : "unobserve"](heading);
    });
}

export default function addMarker(viewportData = {}) {
    // Exit early
    if (typeof window.IntersectionObserver !== "function" || !tocHeadings.length || !tocLinks.length) {
        return;
    }

    init(viewportData.desktop);

    window.addEventListener("navigation:switched", (e) => {
        init(e.detail.desktop);
    });
}
